<template>
<div class="mainform">
  <div class="form">
    <el-form ref="form" class="content bascform" label-width="110px">
        <div>
            <el-form-item label="选择中标单位">
               <el-checkbox-group v-model="checkList">
                <div class="listrow" :key="index" v-for="(item,index) in SupplierList">
                  <el-checkbox :label="item.code">{{item.name}}</el-checkbox>
                  <el-input v-if="checkList.indexOf(item.code)>=0" style="margin-left:10px;" v-model="item.bz" placeholder="请填写备注"></el-input>
                </div>
              </el-checkbox-group>
            </el-form-item>
        </div>
        <div>
            <el-form-item label="备注">
                 <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="bz">
                 </el-input>
            </el-form-item>
        </div>
        <div>
            <el-form-item label="是否同步SAP">
               <el-checkbox-group v-model="checkList">
                <div class="tdlistrow">
                    <el-radio v-model="isSyncSap" label="1">是</el-radio>
                    <el-radio v-model="isSyncSap" label="2">否</el-radio>
                </div>
              </el-checkbox-group>
            </el-form-item>
        </div>
    </el-form>
  </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'ConfirmBid',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      SupplierList: this.formData.bidSupplierList,
      checkList: [],
      isSyncSap: '1',
      bz: ''
    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
 .listrow{
   display: flex;
 }
 .tdlistrow{
   display: flex;
   margin-top: 10px;
 }
</style>
